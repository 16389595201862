import React from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NavBar from "./components/NavBar";
import Projects from "./components/Projects";
import Skill from "./components/Skill";
import "../src/js/NavBarCollapse";
import "./App.css";
import Contribute from "./components/Contribute";

//!Parallax
import { Parallax } from "react-scroll-parallax";
import { ParallaxProvider } from "react-scroll-parallax";
import WorkGoing from "./components/WorkGoing";


function App() {
  return (
   
      <div className="appMain">
        <ParallaxProvider>
          <NavBar />
          <WorkGoing />
          <Header />
          <div className="appSpanDiv">
            <div className="falseClass"></div>
            <div className="reqDiv">
              <Parallax
                speed={5}
                translateY={[10, -50]}
                className="parallaxContainer"
              >
                <Skill />
              </Parallax>
              <Parallax
                speed={4}
                translateY={[10, -100]}
                className="parallaxContainer"
              >
                <Projects />
              </Parallax>
            </div>
          </div>
          <Contribute />
          <Footer />
        </ParallaxProvider>
      </div>
  );
}

export default App;
