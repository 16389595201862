import React from 'react'
import "../css/WorkGoing.css"
function WorkGoing() {
  return (
    <div className="workMain">
      <div className="workContent">
        <span className="workStatus">Working</span>
        &nbsp;New Website under development.
      </div>
    </div>
  );
}

export default WorkGoing