import React from "react";
import "../css/Header.css";
import { FaLinkedinIn } from "react-icons/fa";
import { FiGithub } from "react-icons/fi";
import ProfileImage from "../assets/images/blue-emoji-3.png";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import downLoad from "../assets/images/downLoadIcon.png";
import googleDrive from "../assets/images/googleDrive.png";
import contact from "../assets/images/Contact.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "400px",
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  borderRadius: 2,
  boxShadow: 24,
  textAlign: "center",
};
function Header() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="headerMain" name="home">
      <div className="headerContainer">
        <div className="textSection">
          <div className="subtleHeaderBackOne">Web Developer</div>
          <div className="subtleHeaderBackTwo">Competitive Coder</div>
          <div className="textHeading">
            Hello! I'm Ayan, a{" "}
            <span className="highlight-container span1">
              <span className="highlight">developer</span>
            </span>
            &nbsp; based in India.
          </div>

          {/* <span className="highlight-container">
            <span className="highlight"></span>
          </span> */}

          <div className="textDesc">
            I love building tools that are{" "}
            <span className="highlight-container span2">
              <span className="highlight">user-friendly, simple</span>
            </span>
            &nbsp;and &nbsp;
            <span className="highlight-container span3">
              <span className="highlight">delightful</span>
            </span>
            .<br></br>
            <br></br>I am currently a final year Computer Science and
            Engineering undergraduate student at Kalinga Institute of Industrial
            Technology, Bhubaneswar. I'm interested in web-development and learnt the fundamentals
            of&nbsp;
            <span className="highlight-container span4">
              <span className="highlight">front-end</span>
            </span>
            &nbsp; and &nbsp;
            <span className="highlight-container span5">
              <span className="highlight">back-end</span>
            </span>
            . I also work for MSC KIIT where my role is split between helping
            teams build websites for the community and overseeing other student
            teams.<br></br>
            <br></br> Through these experiences, I had the opportunity to work
            in a community with cross-functional teams, thus developed a working
            style that leans towards &nbsp;
            <span className="highlight-container span6">
              <span className="highlight">flexibility</span>
            </span>
            , &nbsp;
            <span className="highlight-container span7">
              <span className="highlight">clarity</span>
            </span>
            , and &nbsp;
            <span className="highlight-container span8">
              <span className="highlight">collaboration</span>
            </span>
            .<br></br>
            <br></br>
            I'm currently looking for a new role as a developer.{" "}
            <span className="circle-sketch-highlight" onClick={handleOpen}>
              Hire me?
            </span>
          </div>
          <div className="textSectionButtonsDiv">
            <a href="https://www.linkedin.com/in/acayan/">
              <button className="textSectionButton textSectionLinkedin">
                <FaLinkedinIn />
                &nbsp; View Linkedin
              </button>
            </a>
            <a href="https://github.com/ac-ayan">
              <button className="textSectionButton">
                <FiGithub />
                &nbsp; View Github
              </button>
            </a>
          </div>
        </div>
        <div className="imageSection">
          <img src={ProfileImage} alt="" />
        </div>
      </div>
      {/* ---------------------Modal------------------ */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="hireModal">
              <div className="hireButtonList">
                <a href="https://drive.google.com/file/d/1pT9c3xhQZDiAeSZ0nyWyUWFfpFElZqMZ/view?usp=sharing">
                  <button>
                    <img src={googleDrive} alt="" width="40px" />
                    &nbsp;Resume
                  </button>
                </a>
                <a href="https://drive.google.com/uc?export=download&id=1pT9c3xhQZDiAeSZ0nyWyUWFfpFElZqMZ">
                  <button>
                    <img src={downLoad} alt="" width="40px" />
                    &nbsp;Resume
                  </button>
                </a>
                <a href="">
                  <button>
                    <img src={contact} alt="" width="40px" />
                    &nbsp;Contact Me
                  </button>
                </a>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default Header;
