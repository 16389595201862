
window.addEventListener("DOMContentLoaded", () => {
  const hamBurger = document.querySelector(".hamBurger");
  const navBarLinks = document.querySelector(".navBarLinks");
  const navLinksEach = document.querySelectorAll(".linkItem");
  const brandText = document.querySelector("#brandText");
  const brandLogo = document.querySelector("#brandLogo");

  hamBurger.addEventListener("click", () => {
    navBarLinks.classList.toggle("open");
    // navLinksEach.forEach((element) => {
    //   element.classList.toggle("active");
    // });
  });
  navLinksEach.forEach(item => {
    item.addEventListener("click", () => {
      navBarLinks.classList.remove("open");
    })
  })
  brandLogo.addEventListener("click", () => {
    navBarLinks.classList.remove("open");
  })

  // window.addEventListener("load", function () {
  //   const brandLogo = document.getElementById("brandLogo");
  //   if (window.matchMedia("(min-width: 632px)").matches) {
  //     brandLogo.style.display = "none";
  //     // brandText.style.display = "unset";
  //   } else {
  //     brandLogo.style.display = "unset";
  //     // brandText.style.display = "none";
  //   }
  // });
  // window.addEventListener("resize", function () {
  //   const brandLogo = document.getElementById("brandLogo");
  //   if (window.matchMedia("(min-width: 632px)").matches) {
  //     brandLogo.style.display = "none";
  //     // brandText.style.display = "unset";
  //   } else {
  //     brandLogo.style.display = "unset";
  //     // brandText.style.display = "none";
  //   }
  // });
});
