import techColumns from "../dbAssets/gifs/techColumns-gif.gif";
import flight404 from "../dbAssets/gifs/flight404.gif";
import wordle from "../dbAssets/gifs/wordle.gif";
import galaxy from "../dbAssets/gifs/galaxy.gif";
import convergingGalaxy from "../dbAssets/gifs/convergingGalaxy.gif";
import hauntedHouse from "../dbAssets/gifs/hauntedHouse.gif";
import realRender from "../dbAssets/gifs/realRender.gif";
const projectsData = [
  {
    img: techColumns,
    colorCode: "#6b46c1",
    website: "https://tech-columns.netlify.app/",
    title: "Tech-Columns",
    desc: "It’s a web app, which provides a platform, where the experiences of seniors and their suggestions about their domain are put in form of blogs to help other students.",
    tagList: [
      "React",
      "react-bootstrap",
      "JavaScript",
      "Node",
      "express",
      "MongoDB",
    ],
    github: "./",
    web: "https://tech-columns.netlify.app/",
    status: "Private",
  },
  {
    img: wordle,
    colorCode: "#f3cd46",
    website: "https://ayan-chakraborty.me/wordle-plus-plus/",
    title: "Wordle++",
    desc: "This is a customized version of wordle game with many interesting features to try! Its build using HTML,CSS and Javascript.",
    tagList: ["HTML", "CSS", "JavaScript"],
    status: "Public",
    github: "https://github.com/ac-ayan/wordle-plus-plus",
    web: "https://ac-ayan.github.io/wordle-plus-plus/",
  },
  {
    img: flight404,
    colorCode: "#00a3c4",
    website: "https://github.com/ac-ayan/flight404",
    title: "Flight404",
    desc: "It’s a web-based 3D game, developed using the popular Three.js library along with other tech stacks like HTML and CSS.",
    tagList: ["Three.js", "HTML", "CSS", "JavaScript"],
    status: "Public",
    github: "https://github.com/ac-ayan/flight404",
    web: "https://ac-ayan.github.io/flight404/",
  },
  {
    img: galaxy,
    colorCode: "#26df82",
    website: "https://ayan-chakraborty.me/galaxy-threejs/",
    title: "Galaxy Generator",
    desc: "A mini project using Three.js library, creating a galaxy and several tweaks to play with in web. This contains Orbit Controls, tweaks include changing radius, colors etc.",
    tagList: ["HTML", "CSS", "JavaScript"],
    status: "Public",
    github: "https://github.com/ac-ayan/galaxy-threejs",
    web: "https://ac-ayan.github.io/galaxy-threejs/",
  },
  {
    img: hauntedHouse,
    colorCode: "#6b46c1",
    website: "https://ayan-chakraborty.me/haunted-house/",
    title: "Haunted House",
    desc: "A mini project build with Three.js library, presenting a haunted house using Buffer Geometries, textures, shadows along with different scenic implementations.",
    tagList: ["Three.js", "HTML", "CSS", "JavaScript"],
    status: "Public",
    github: "https://github.com/ac-ayan/haunted-house",
    web: "https://ac-ayan.github.io/haunted-house/",
  },
  {
    img: realRender,
    colorCode: "#f3cd46",
    website: "https://ayan-chakraborty.me/Realistic-render/",
    title: "Realistic Render",
    desc: "A mini project build using Three.js, for creating realistic renders of blender models in web. Providing realism with several properties provided by the library.",
    tagList: ["Three.js", "HTML", "CSS", "JavaScript"],
    status: "Public",
    github: "https://github.com/ac-ayan/Realistic-render",
    web: "https://ac-ayan.github.io/Realistic-render/",
  },
  {
    img: convergingGalaxy,
    colorCode: "#00a3c4",
    website: "https://ayan-chakraborty.me/Converging-Galaxy/",
    title: "Converging Galaxy",
    desc: "This mini project is in continuation with the galaxy project. This is all about rotating and converging the 3D Galaxy.",
    tagList: ["Three.js", "HTML", "CSS", "JavaScript"],
    status: "Public",
    github: "https://github.com/ac-ayan/Converging-Galaxy",
    web: "https://ac-ayan.github.io/Converging-Galaxy/",
  },
];
export default projectsData;
