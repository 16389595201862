import React from "react";
import "../css/NavBar.css";
import "../js/NavBarCollapse";
import logo from "../assets/images/SAMPLE-4.png";
import { Link } from "react-scroll";
function NavBar() {
  return (
    <div className="navBarMain">
      <div className="navBarContainer">
        <Link to="home" smooth={true} offset={-200}>
          <div className="brandName">
            <img src={logo} id="brandLogo" alt=""></img>
            <span id="brandText">AYAN CHAKRABORTY</span>
          </div>
        </Link>
        <div className="hamBurger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div className="navBarLinks">
          <Link to="projects" smooth={true} offset={-500} className="linkItem">
            Profiles
          </Link>
          <Link to="projects" smooth={true} offset={-500} className="linkItem">
            Projects
          </Link>
          {/* <a href="./">Blogs</a>  */}
          <Link to="projects" smooth={true} offset={-500} className="linkItem">
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
