import React from "react";
import "../css/SkillCard.css";
function SkillCardTemplate(props) {
  return (
    <div className="skillCardMain">
      <div className="skillImage" style={{"background-color":props.colorCode}}>
        {props.skillImage}
      </div>
      <div className="skillDescBox" style={{"border-bottom": "4px solid "+props.colorCode}}>
        <div className="skillCardTitle">{props.title}</div>
        <div className="skillCardDesc">{props.desc}</div>
      </div>
    </div>
  );
}

export default SkillCardTemplate;
