import React from "react";
import "../css/Footer.css";
function Footer() {
  return (
    <div className="footerMain">
      <div className="footerContainer">
        <div className="footerHeading">Get in touch</div>
        <div className="footerDesc">
          Do you have a job opportunity or idea you'd like to discuss? Feel free
          to reach me out at{" "}
          <span className="emailAddress">ayanchakraborty.inbox@gmail.com</span>.
          You can also find me on &nbsp;
          <a href="/">
            <span>Twitter</span>
          </a>
          ,&nbsp;
          <a href="https://github.com/ac-ayan">
            <span>Github</span>
          </a>{" "}
          and&nbsp;
          <a href="https://www.linkedin.com/in/acayan">
            <span>Linkedin</span>
          </a>
          .
        </div>
        <div className="footerSeparator"></div>
        <div className="footerCopyRight">
          Built with ❤️ by Ayan Chakraborty.
        </div>
      </div>
    </div>
  );
}

export default Footer;
