import React from "react";
import "../css/contribute.css";
import { FiGithub } from "react-icons/fi"
import { AiOutlineStar } from "react-icons/ai";
function Contribute() {
  return (
    <div className="contributeMain">
      <div className="contributeContainer">
        <div className="contributeHeading">Collaborate on Projects?</div>
        <div className="contributeSubHeading">Backed by open-source.</div>
        <div className="contributeDesc">
          Have projects in mind? Need somebody to discuss and jointly work with?
          <br></br>
          <br></br>
          Do let me know. Let's discuss and bring those ideas to reality.✌️
          <br></br> You can also contribute to some of my projects, I'm working
          on or can also send me your feedbacks. I would really appreciate it.
        </div>
        <div className="contributeButtonDiv">
          <a href="https://github.com/ac-ayan">
            <button>
              Contribute&nbsp;
              <FiGithub />
            </button>
          </a>
          <a href="https://github.com/ac-ayan">
            <button>
              Star this site&nbsp;
              <AiOutlineStar />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contribute;
