import React from "react";
import "../css/ProjectCard.css";
import "../js/projectModal";
import "../css/Modal.css"
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import logo1 from "../assets/images/github.png";
import logo2 from "../assets/images/globe4.png";

import { BsArrowRight } from "react-icons/bs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "400px",
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  borderRadius: 2,
  boxShadow: 24,
  textAlign: "center",
};
function ProjectCardTemplate(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  var statement = "";
        if (props.status === "Public") {
          statement = "You can see or contribute";
        } else {
          statement = "Not open for contribution yet.";
        }
  return (
    <div>
      <div className="ProjectCardEntire">
        <div className="projectsCardMain" onClick={handleOpen}>
          <div className="projectCardContainer">
            <div className="upperSection">
              <div className="quickActions">
                <div className="circle c1"></div>
                <div className="circle c2"></div>
                <div className="circle c3"></div>
              </div>
              <div className="searchBar">{props.web}</div>
              <div className="arrowHead">
                <BsArrowRight />
              </div>
            </div>

            <div className="projectMainContent">
              <img src={props.img} alt=""></img>
              {/* <ReactPlayer
              className="videoPlay"
              url={techColumns}
              playing={true}
              loop={true}
            /> */}
            </div>
          </div>
        </div>
        <div className="cardDetails">
          <div className="projectTitle" style={{ color: props.colorCode }}>
            {props.title}
          </div>
          <div className="projectDesc">{props.desc}</div>
          <div className="projectTagList">
            {props.tagList.map((val, index) => {
              return <div className="projectTag">{val}</div>;
            })}
          </div>
          <div className="statusDiv">{props.status}</div>
          <div className="externalLinks">
            <div className="githubLink">{props.github}</div>
            <div className="webLink">{props.web}</div>
          </div>
        </div>
      </div>
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style} className="modalContainer">
              <div className="projectHeaderText">{props.title}</div>
              <div className="statusCode">
                <span className="colorChange">{props.status}</span>&nbsp;Github
                repository is {props.status}.&nbsp;{statement}
              </div>
              <div className="projectDescText">{props.desc}</div>

              <div className="modalButtonList">
                <a href={props.github}>
                  <button className="githubLink">
                    <img src={logo1} alt="" width="25px" />
                    &nbsp;View Github
                  </button>
                </a>
                <a href={props.web}>
                  <button className="liveLink">
                    <img src={logo2} alt="" width="25px" />
                    &nbsp;View Live
                  </button>
                </a>
              </div>
            </Box>
            {/* <div class="modalContainer" sx={style}>
              <div class="projectHeaderText">{props.title}</div>
              <div class="statusCode">
                <span class="colorChange">{props.status}</span>&nbsp;Github
                repository is .&nbsp;
              </div>
              <div class="projectDescText">{props.desc}</div>
              <div class="modalButtonList">
                <a href="">
                  <button class="githubLink">
                    <img src="" width="25px" />
                    &nbsp;View Github
                  </button>
                </a>
                <a href="">
                  <button class="liveLink">
                    <img src="" width="25px" />
                    &nbsp;View Live
                  </button>
                </a>
              </div>
            </div> */}
          </Fade>
        </Modal>
      </div>
    </div>
  );
}

export default ProjectCardTemplate;
