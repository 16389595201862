import React from "react";
import "../css/Projects.css";
import data from "../data/db/projectsData";
import ProjectCardTemplate from "./ProjectCardTemplate";

function Projects() {
  return (
    <div className="projectsMain" name="projects">
      <div className="projectsContainer">
        <div className="subtleBackProject">Projects</div>
        {/* <div className="projectsHeading">Projects</div> */}
        {/* <div className="projectsSubHeading">
          My projects are mostly based on web development tech stacks like HTML,
          CSS, JavaScript, MERN Stack along with different JS libraries.
        </div> */}
        <div className="projectsList">
          {data.map((val, index) => {
            return (
              <ProjectCardTemplate
                key={index}
                img={val.img}
                title={val.title}
                desc={val.desc}
                website={val.website}
                colorCode={val.colorCode}
                tagList={val.tagList}
                web={val.web}
                github={val.github}
                status ={val.status}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Projects;
