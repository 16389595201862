import { TiGlobeOutline } from "react-icons/ti";
import { BsFillFileCodeFill } from "react-icons/bs";
import { BiGitMerge } from "react-icons/bi";
import { BsTerminalFill } from "react-icons/bs";
import { BiNetworkChart } from "react-icons/bi";
import { ImDatabase } from "react-icons/im";

const skillData = [
  {
    skillIcon: <TiGlobeOutline />,
    colorCode: "red",
    title: "Web Development",
    desc: "Fundamentals of web designing and development. Experienced in HTML, CSS, JavaScript,MongoDB, express.js, React.js and Node.js (MERN). Frontend development is ❤️.",
  },
  {
    skillIcon: <BsFillFileCodeFill color="#C8E3D4" />,
    colorCode: "#519259",
    title: "Programming",
    desc: "Fundamentals of programming concepts and problem solving with C, C++ and Python. Be it competitive programming or real-world problems, always excited to solve them.",
  },
  {
    skillIcon: <BiGitMerge />,
    colorCode: "#e84d31",
    title: "Git & Github",
    desc: "Knowledge of Version Control Systems (specifically Git) and using it in development of projects both small and large. Its integration to track and collaborate with teams.",
  },
  {
    skillIcon: <BsTerminalFill color="white" />,
    colorCode: "#395B64",
    title: "Basic Terminal",
    desc: "Knowledge of the basic operations and file and folder handlings, along with some knowledge of terminal editor viz. vim, nano. Using command-line interface for the development and deployment purpose.",
  },
  {
    skillIcon: <BiNetworkChart />,
    colorCode: "#519259",
    title: "Data Structure & Algorithms",
    desc: "Fundamentals concepts of Data Structures starting from arrays to graphs and their implementations and use cases to solve real world problems. Besides this, also having knowledge of some important algorithms to solve specific type of problems efficiently.",
  },
  {
    skillIcon: <ImDatabase color="#D9D7F1" />,
    colorCode: "#577BC1",
    title: "Oracle Database",
    desc: "Basic understanding of Database Management System and basic operations (CRUD Operations), Queries, different types of relationships of data in databases, normalization, MetaData, Records and their manupulation. ",
  },
];
export default skillData;
