import React from "react";
import "../css/Skill.css";
import skillData from "../data/db/skillData";
import ProjectCardTemplate from "../components/SkillCardTemplate";
function Skill() {
  return (
    <div className="skillMain">
      <div className="skillContainer">
        {/* <div className="skillHeading">Skills</div> */}
        <div className="SubtleBack">Skills</div>
        <div className="skillList">
          {skillData.map((val, index) => {
            return (
              <ProjectCardTemplate
                skillImage={val.skillIcon}
                title={val.title}
                desc={val.desc}
                colorCode={val.colorCode}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Skill;
